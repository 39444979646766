<template>
  <div class="container home3">
    <div class="row">
      <maintop1 ref="newmesstop" />
      <maintop2 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
        class="header_mt"
      >
        <div class="main-casino-banner_banner__17iXb w-100">
          <img
            src="@/assets/img/slot/slots.png"
            class="main-casino-banner_image__20B7N w-auto"
          />
          <div class="main-casino-banner_inner__AsuW3">
            <h1 class="main-casino-banner_title__1yext">
              {{
                type.indexOf("A1,") != -1
                  ? type.toUpperCase().split(",")[1]
                  : type
              }}-Slots
            </h1>
            <p class="main-casino-banner_description__ZTMcp">
              {{ $t("slot_text1") }}
            </p>
          </div>
        </div>

        <div>
          <div class="d-flex justify-content-end">
            <div class="search-input search-input_search__2jkZ6">
              <input
                placeholder="Search game"
                class="input_place"
                v-model="keyWord"
              /><span class="svg-icon q-icon">
                <img
                  src="@/assets/img/main/search.png"
                  :title="$t('slot_Search')"
                />
              </span>
            </div>
          </div>

          <div class="live_casino">
            <div class="live_casino_card">
              <div class="game_slider_card">
                <div
                  v-for="l in filterSearch.slice(0, newVal)"
                  :key="l"
                  class="filter_card"
                  @click="
                    game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                  "
                >
                  <div
                    class="filter_card1"
                    v-lazy:background-image="
                      'http://gameweb.metaversement.io/' + l.image
                    "
                  ></div>
                  <div class="mask position-absolute top-0 start-0"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex w-25 mx-auto align-items-center mb-2 justify-content-between w-lg-75"
          >
            <span class="w-25">{{ newVal }}/{{ maxVal }}</span>
            <div class="progress mx-2 w-100">
              <div
                class="progress-bar bg-success"
                :style="{
                  width: percent + '%',
                }"
              ></div>
            </div>
            <span class="w-25 text-end">{{ percent }}%</span>
          </div>
          <div class="w-25 mx-auto text-center w-lg-75">
            <button
              class="w-100 btn btn-success"
              @click="more()"
              v-show="this.percent == 100 || this.percent == 0 ? false : true"
            >
              <a>{{ $t("slot_text2") }}</a>
            </button>
          </div>
        </div>

        <div class="w-100 fw-bolder my-3">{{ $t("slot_text3") }}</div>
        <div class="w-100">
          <img
            :src="require('@/assets/img/game/all/slot_' + l + '.png')"
            :class="css_list"
            @click="type = l.toUpperCase()"
            v-for="l in slot_game"
            :key="l"
          />
          <br />
          <img
            v-for="l in allinslot"
            :key="l"
            :src="require('@/assets/img/allinslot/' + l + '.png')"
            class="col-lg-2 col-6 mb-2 px-2"
            @click="type = 'A1,' + l.toUpperCase()"
          />
        </div>
      </div>

      <Footer />
    </div>
    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import newWindow from "./iframe.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "slot",
  data() {
    return {
      code: Cookies.get("code"),
      css_list: "col-lg-2 col-6 mb-2 px-2 c-pointer",
      keyWord: "",
      maxVal: 0,
      newVal: 0,
      index: 1,
      type: localStorage.getItem("g"),
      newWindowurl: "",
      slot_game: [
        "pg",
        "fachai",
        // "jili",
        "ps",
        "ka",
        "funta",
        // "rich",
        "pp",
        // "CQ9",
      ],
      allinslot: [
        "AINSWORTH",
        "AMATIC",
        "APEX",
        "APOLLO",
        "ARISTOCRAT",
        "EGT",
        "gclub",
        "igrosoft",
        "igt",
        "microgaming",
        "netent",
        "novomatic",
        "pragmatic",
        "quickspin",
        "scientific_games",
        "vegas",
        "wazdan",
      ],
    };
  },
  computed: {
    ...mapGetters(["list", "flag_nav"]),
    filterSearch() {
      return this.list.filter((v) => {
        if (this.keyWord.length > 0) {
          return v.gamename.toLowerCase().includes(this.keyWord.toLowerCase());
        } else return v;
      });
    },
    percent() {
      return this.newVal == 0
        ? 0
        : ((this.newVal * 100) / this.maxVal).toFixed();
    },
  },
  components: {
    Footer,
    maintop1,
    maintop2,
    newWindow,
  },
  watch: {
    type: function (e) {
      localStorage.setItem("g", e);
      this.gamelist(e);
    },
    filterSearch() {
      this.index = 1;
      this.maxVal = this.filterSearch.length > 0 ? this.filterSearch.length : 0;
      this.newVal =
        this.filterSearch.length > 20 ? 20 : this.filterSearch.length;
    },
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$refs.newmesstop.getnewmess();
      }
    },
  },
  created() {
    this.gamelist(this.type);
  },
  mounted() {},
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      this.newVal = 0;
      var gametype =
        t.toUpperCase().indexOf("A1,") != -1
          ? t.toUpperCase().split(",")[0]
          : t;
      var type =
        t.toUpperCase().indexOf("A1,") != -1
          ? t.toUpperCase().split(",")[1]
          : "all";
      let param = {
        action: "GameLobby",
        body: {
          gametype: gametype,
          type: type,
        },
      };
      this.getGameList(param)
        .then((finalResult) => {
          this.index = 1;
          this.maxVal = this.list.length > 0 ? this.list.length : 0;
          this.newVal = this.list.length > 20 ? 20 : this.list.length;
          document.getElementById("app").scrollTop = 0;
          console.log(finalResult);
        })
        .catch();
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    more: function () {
      if (this.index <= Math.floor(this.maxVal / 20)) this.index++;
      this.newVal =
        this.list.length > 20 * this.index ? 20 * this.index : this.list.length;
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
};
</script>
